.app {
  background-color: #1E1E1E;
  color: white;
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
}
.profile {
  display: flex;
  align-items: center;
}
.profile img {
  border-radius: 50%;
  margin-right: 10px;
}
.profile-info p {
  margin: 0;
}

.attendance-summary {
  display: flex;
  flex-direction: column; /* Change to column layout */
  background-color: #333;
  align-items: start; /* Center-align contents */
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.attendance-summary h3 {
  font-size: 1.2em;
  margin-bottom: 10px; /* Add space below the header */
}

.attendance-details {
  display: flex;
  justify-content: space-around;
  width: 100%; /* Make sure "누적" and "이번 달" take the full width */
}

.attendance-summary div {
  text-align: center;
}

.calendar {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
}
/* Dark mode styles for react-calendar */
.react-calendar {
  background-color: #333;
  border: none;
  border-radius: 10px;
  color: white;
}
.react-calendar__tile {
  background-color: #333;
  color: white;
  border-radius: 30px; /* Add rounded corners */
  padding: 10px; /* Adjust padding to enhance roundness */
  margin: 5px; /* Add margin to create spacing between tiles */
}
.react-calendar__tile--active {
  background-color: #919591 !important;
  color: white !important;
  border-radius: 10px; /* Maintain rounding for active tile */
}
.react-calendar__tile--now {
  background-color: #1E90FF;
  color: white;
  border-radius: 10px; /* Maintain rounding for current date */
}
.react-calendar__navigation button {
  color: white;
  background-color: #333;
  border: none;
}
.react-calendar__navigation button:hover {
  background-color: #333;
}
.react-calendar__navigation button[disabled] {
  background-color: #333; /* Match with calendar background color */
  color: #333; /* Make text the same color as background to hide it */
}
.react-calendar__navigation__label {
  pointer-events: none; /* Disables clicks and interactions */
  background-color: #333; /* Matches the calendar background */
  color: #fff; /* Text color */
  font-weight: bold;
}

.calendar-container {
  margin: 20px 0;
  text-align: left; /* Ensure left alignment for contents */
  font-size: 0.7em;
}
.present {
  background-color: #4CAF50 !important;
  color: white !important;
  border-radius: 10px; /* Add rounded corners */
}
.cta-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.cta-button:hover {
  background-color: #0056B3;
}